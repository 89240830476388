import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from 'gatsby-image'


class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const { author } = this.props.data.site.siteMetadata
    const siteSlogan = this.props.data.site.siteMetadata.slogan
    const { featuredImage: ogimage } = post.frontmatter
    const ogImagePath = ogimage && ogimage.childImageSharp.fluid.src

    return (
      <Layout location={this.props.location} title={siteTitle} slogan={siteSlogan} >
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={ogImagePath}
          frontmatter={post.frontmatter}
          isBlogPost
        />
        <main>
          <div className="headerpostinfo">
            <time dateTime={post.frontmatter.date}>{post.frontmatter.date}</time>
            <span className="author"> by {author}</span>          
          </div>
          <h1>
            {post.frontmatter.title}
          </h1>
          <Img fluid={post.frontmatter.featuredImage.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" alt="" className="img-fluid"></Img>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr
            style={{
              marginBottom: '10px',
            }}
          />

          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
              marginTop: '25px',
              marginLeft: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </main>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        slogan
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        datePublished: date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
        dateModified(formatString: "YYYY-MM-DDTHH:mm:ssZ")
        description
        featuredImage {
          childImageSharp{
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        slug
      }
    }
  }
`
